<template>
	<div class="tw-w-full tw-p-4 tw-bg-white tw-mb-8 tw-rounded-lg">
		<div class="tw-text-black-light tw-text-xs">{{ header }}</div>
    <div v-if="!header"
      class="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-x-2 lg:tw-items-center"
    >
      <div class="tw-mb-8 lg:tw-mb-0">
        <div
          class="tw-flex tw-gap-2 tw-items-center tw-rounded-lg tw-p-[10px] tw-border-solid tw-border-1 tw-border-pastel3 tw-w-[166px] box-shadow"
        >
          <CurrencyFlag
            v-if="selectedCurrency"
            :currency="selectedCurrency"
          />
          <select
            name="currency"
            id="currency"
            v-model="selectedCurrency"
            class="tw-border-0 tw-h-6 tw-text-black-light tw-text-base wallet__select"
          >
            <option :value="wallet.currency" v-for="wallet in walletBalance">
              {{ wallet.currency }} Balance
            </option>
          </select>
        </div>
        <p
          class="tw-text-2xl tw-mt-1 tw-text-secondary !tw-font-CircularStd-medium tw-font-medium"
        >
          {{
            formatAmountToDollar(
              selectedWallet.balance,
              2,
              selectedWallet.currency
            )
          }}
        </p>
      </div>
      <div class="tw-justify-self-end">
        <Btn
          title="+ Fund Wallet"
          :plus="true"
          @click="$emit('fund-wallet')"
        />
      </div>
    </div>
		<template v-if="header">
			<div v-if="header === 'Deals'" class="tw-text-2xl tw-mt-2 tw-mb-4">
				{{ deals.total?.toLocaleString() }}
			</div>
			<div v-if="header === 'Members'" class="tw-text-2xl tw-mt-2 tw-mb-4">
				{{ members.total?.toLocaleString() }}
			</div>
			<div v-if="header === 'Deals'" class="tw-flex tw-text-xs tw-text-gray">
				<div class="tw-flex tw-items-center tw-mr-4">
					<img
						class="tw-w-4 tw-mr-1"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1634261166/getequityV2/active_fichvp.webp"
						alt="active"
						loading="lazy"
					/>
					<p class="tw-font-CircularStd-medium tw-font-medium">
						Active: {{ deals.approved?.toLocaleString() }}
					</p>
				</div>
				<div class="tw-flex tw-items-center tw-mr-4">
					<img
						class="tw-w-4 tw-mr-1"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1634261166/getequityV2/pending_ctj1ke.webp"
						alt="pending"
						loading="lazy"
					/>
					<p class="tw-font-CircularStd-medium tw-font-medium">
						Pending: {{ deals.pending?.toLocaleString() }}
					</p>
				</div>
				<div class="tw-flex tw-items-center tw-mr-4">
					<img
						class="tw-w-4 tw-mr-1"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1634261166/getequityV2/denied_sbmv0e.webp"
						alt="denied"
						loading="lazy"
					/>
					<p class="tw-font-CircularStd-medium tw-font-medium">
						Denied: {{ deals.rejected?.toLocaleString() }}
					</p>
				</div>
			</div>
			<div v-if="header === 'Members'" class="tw-flex tw-text-xs tw-text-gray">
				<div class="tw-flex tw-items-center tw-mr-4">
					<img
						class="tw-w-4 tw-mr-1"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1634261166/getequityV2/active_fichvp.webp"
						alt="active"
						loading="lazy"
					/>
					<p class="tw-font-CircularStd-medium tw-font-medium">
						Active: {{ members.active?.toLocaleString() }}
					</p>
				</div>
				<div class="tw-flex tw-items-center tw-mr-4">
					<img
						class="tw-w-4 tw-mr-1"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1634261166/getequityV2/pending_ctj1ke.webp"
						alt="pending"
						loading="lazy"
					/>
					<p class="tw-font-CircularStd-medium tw-font-medium">
						Pending: {{ members.pending?.toLocaleString() }}
					</p>
				</div>
				<div class="tw-flex tw-items-center tw-mr-4">
					<img
						class="tw-w-4 tw-mr-1"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1634261166/getequityV2/denied_sbmv0e.png"
						alt="denied"
						loading="lazy"
					/>
					<p class="tw-font-CircularStd-medium tw-font-medium">
						Deactivated: {{ members.inactive?.toLocaleString() }}
					</p>
				</div>
			</div>
		</template>
	</div>
</template>

<script setup>
import Btn from "@/components/general/BtnComponent.vue";
import CurrencyFlag from "@/components/wallet/CurrencyFlag.vue";
import { formatAmountToDollar } from "@/utils/helpers.js";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
	header: {
		type: String,
		default: () => "",
	},
	deals: {
		type: Object,
		default: () => {},
	},
	members: {
		type: Object,
		default: () => {},
	},
	balance: {
		type: Array,
		default: () => [],
	},
});
defineEmits(["fund-wallet"]);

const walletBalance = computed(() => props.balance);

const selectedWallet = ref("");
const selectedCurrency = ref("");

onMounted(() => {
	if (walletBalance.value && walletBalance.value.length > 0) {
		selectedWallet.value = walletBalance.value[0];
		store.dispatch("organisationModule/setCurrency", selectedWallet.value);
	}
});

watch(walletBalance, (newVal, oldVal) => {
	if (newVal > 0) {
		selectedWallet.value = walletBalance.value[0];
	}
});
watch(selectedCurrency, (newVal, oldVal) => {
	if (newVal) {
		store.dispatch("organisationModule/setCurrency", newVal);
		if (newVal !== selectedWallet.value?.currency) {
			selectedWallet.value = walletBalance.value.find(
				(wallet) => wallet.currency === newVal
			);
		}
	}
});
watch(selectedWallet, (newVal, oldVal) => {
	if (newVal && newVal !== selectedCurrency.value) {
		selectedCurrency.value = newVal.currency;
	}
});
</script>

<style lang="scss" scoped>
.box-shadow {
	border-radius: 8px;
	// border: 1px solid #e5edff;
	background: #fff;
	// box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
	cursor: pointer;
}

.wallet__select {
	appearance: auto;
}
</style>
