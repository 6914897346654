<template>
	<section class="overview__section">
		<Loader v-if="loading" />
		<div v-else class="overview__div tw-px-4 lg:tw-px-8">
			<div class="">
				<h3
					class="tw-text-xl tw-font-bold tw-text-black-dark tw-mb-3 tw-w-full"
				>
					Share link
				</h3>
				<p class="tw-text-sm tw-text-black-light tw-mb-3">
					Your investor Dealroom URL is
				</p>
				<div class="md:tw-flex tw-w-full tw-mb-10">
					<div
						class="tw-rounded tw-flex tw-gap-x-2 tw-bg-blue-bg200 tw-text-black-dark tw-py-3 tw-pl-6 tw-border tw-border-[#C2D3FF] tw-flex-1  tw-rounded-tr-none tw-rounded-br-none"
					>
						<img src="@/assets/img/link-grey.svg" />
						<!-- <p>{{ orgLink }}</p> -->
						<input
							class="tw-w-full tw-break-words tw-border-0"
							type="text"
							readonly
							:value="orgLink"
							@focus="$event.target.select()"
							ref="link"
						/>
					</div>
					<button
						@click="copyInviteLink"
						class="tw-h-12 md:tw-h-auto tw-self-stretch tw-mt-2 md:tw-mt-0 tw-rounded tw-bg-secondary tw-text-white tw-flex tw-items-center tw-justify-center tw-px-6 tw-text-sm tw-font-CircularStd-medium tw-font-medium  tw-rounded-tl-none tw-rounded-bl-none"
					>
						<img src="@/assets/img/copy-icon.svg" /> Copy link
					</button>
				</div>
			</div>
			<h3 class="tw-text-xl tw-font-bold tw-text-black-dark tw-mb-3 tw-w-full">
				Overview
			</h3>
			<div
				class="overview-top__div lg:tw-flex lg:tw-justify-center lg:tw-gap-x-5"
			>
				<OverviewCard :deals="overviewDeals" header="Deals" />
				<OverviewCard :members="overviewMembers" header="Members" />
				<OverviewCard
					:balance="walletBalance"
					@fund-wallet="showFundModal = true"
				/>
			</div>
			<section class="overview-top-deals__section tw-py-6">
				<div class="tw-flex tw-justify-between tw-items-center tw-mb-6">
					<h3 class="tw-font-bold">Top deals</h3>
					<router-link
						to="/dashboard/deals"
						class="tw-text-sm tw-text-primary tw-font-CircularStd-medium tw-font-medium"
					>
						View all deals
					</router-link>
				</div>
				<div
					v-if="topDeals.length !== 0"
					class="overview-top-deals__div tw-bg-white tw-py-8 tw-px-3 lg:tw-px-8 lg:tw-py-10"
				>
					<OverviewDealsCard
						:topDeals="deal"
						v-for="deal in topDeals"
						:key="deal._id"
					/>
				</div>

				<section
					class="overview-top-deals__div tw-bg-white tw-py-8 tw-px-3 lg:tw-px-8 lg:tw-py-10"
					v-else
				>
					<EmptyState class="lg:tw-col-start-2">
						<template v-slot:message>
							<p class="message tw-text-center tw-text-gray tw-my-3">
								There are no deals to show at the moment
							</p>
							<router-link
								:to="{ name: 'Deals', query: { showAssetSearch: true } }"
								class="tw-text-primary"
								>Create a Deal</router-link
							>
						</template>
					</EmptyState>
				</section>
			</section>
			<div class="table-container tw-mt-12 tw-pb-24">
				<h3 class="tw-font-bold tw-text-xl tw-text-black-dark tw-mb-3">
					Recent transactions
				</h3>
				<div class="">
					<TransactionTable bgColor="tw-bg-white" />
				</div>
			</div>
		</div>
		<FundModal v-if="showFundModal" @close-modal="closeModal" />
	</section>
</template>

<script>
import EmptyState from "@/components/notification/EmptyState";
import OverviewCard from "@/components/overview/OverviewCard";
import OverviewDealsCard from "@/components/overview/OverviewDealsCard";
import TransactionTable from "@/components/tables/TransactionTable";
import FundModal from "@/components/wallet/fund/FundModal.vue";
import { removeItem } from "@/utils/storage.js";
import { mapActions, mapGetters } from "vuex";

export default {
	name: "OverviewDetails",

	components: {
		OverviewCard,
		OverviewDealsCard,
		TransactionTable,
		EmptyState,
    FundModal
	},

	data() {
		return {
			showFundModal: false,
		};
	},

	computed: {
		...mapGetters(["loading"]),
		...mapGetters("organisationModule", [
			"walletBalance",
			"overviewTokens",
			"overviewMembers",
			"overviewDeals",
			"organisationDetails",
		]),
		orgLink() {
			return `${this.organisationDetails.slug}.getequity.co`;
		},
		topDeals() {
			const tokens = [...this.overviewTokens]
			const sortedTopDeals = tokens.sort(function(a, b) {
				return parseFloat(b.total_raised) - parseFloat(a.total_raised);
			});
			return sortedTopDeals.splice(0, 3);
		},
	},

	created() {
		this.getOverviewDetails();
	},
	watch: {
		organisationDetails(val) {
			if(!val.verified) {
				this.$router.push({name: 'CodeVerification'})
			}
		}
	},

	methods: {
		...mapActions("organisationModule", [
			"getTransactions",
			"getWalletBalance",
			"getOrganisationOverview",
		]),
		...mapActions(["showToast"]),

		async getOverviewDetails() {
			let overviewDetails = this.getOrganisationOverview();
			let balance = this.getWalletBalance();
			try {
				await Promise.all([overviewDetails, balance]);
			} catch (error) {
				return error;
			}
		},
		copyInviteLink() {
			this.$refs.link.focus();

			// TODO: Look for an alternative to execCommand
			document.execCommand("copy");
			this.showToast({
				description: "Link copied",
				display: true,
				type: "success",
			});
		},

		closeModal() {
			this.showFundModal = !this.showFundModal;
			removeItem("fundPayload");
		},
	},
};
</script>

<style lang="scss" scoped>
.overview {
	&__section {
		width: 100%;
		overflow: hidden;
	}

	&__div {
		@include fadeIn;
		@media screen and (min-width: 1024px) {
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&-top {
		&__div {
			@media screen and (min-width: 1024px) {
				max-width: 1280px;
				display: grid;
				grid-template-columns: 250px 250px 1fr;
				margin-left: auto;
				margin-right: auto;
			}
			@media screen and (min-width: 1280px) {
				grid-template-columns: 330px 330px 1fr;
			}
		}
		&-deals {
			&__div {
				display: grid;
				row-gap: 1rem;
				border: 1px solid #f1f3f6;
				box-shadow: 0px 2px 8px rgba(11, 40, 105, 0.04);

				@media screen and (min-width: 1024px) {
					grid-template-columns: repeat(3, 1fr);
					column-gap: 2rem;
				}
			}
		}
	}

	.table-container {
		@media screen and (min-width: 1024px) {
			max-width: 1280px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
</style>
