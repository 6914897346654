<template>
	<router-link
		v-if="topDeals"
		:to="{ name: 'DealInfo', params: { dealId: topDeals.request } }"
		class="overview-deal__link tw-bg-white tw-rounded-lg tw-px-4 tw-py-3"
	>
		<div
			class="tw-flex tw-justify-between tw-items-center tw-bg-white tw-rounded-lg tw-p-2 tw-mb-6"
		>
			<h3 class="tw-font-bold">{{ topDeals.name }}</h3>
			<img class="tw-h-4" :src="topDeals.image" alt="deal" loading="lazy" />
		</div>
		<div class="gauge-container tw-mb-10">
			<div
				class="gauge tw-relative tw-overflow-hidden tw-bg-primary tw-h-1 tw-rounded"
			>
				<div
					class="fill tw-absolute tw-bg-gray-dark tw-w-full tw-h-1 tw-rounded"
					ref="fill"
				></div>
			</div>
			<div class="tw-flex tw-justify-between">
				<p class="tw-text-gray tw-text-xs tw-mt-1">
					{{
						(
							((totalRaised) / topDeals.raise_amount) *
							100
						).toFixed(2)
					}}% raised
				</p>
				<p class="tw-text-gray tw-text-xs tw-mt-1">
					{{
						formatAmountToDollar(
							totalRaised,
							2,
							topDeals.currency || "USD"
						)
					}}
					of
					{{
						formatAmountToDollar(
							topDeals.raise_amount,
							2,
							topDeals.currency || "USD"
						)
					}}
				</p>
			</div>
		</div>
		<div
			class="tw-flex tw-justify-between tw-items-center"
			v-if="topDeals.investors && topDeals.investors.length > 0"
		>
			<h3 class="tw-text-sm tw-text-black-light">Contributors</h3>
			<ul class="investors tw-relative tw-flex">
				<li
					v-for="(investor, index) in topDeals.investors"
					:key="'img' + index"
					class="tw-relative"
				>
					<div
						class="overlay tw-absolute tw-bg-black tw-w-full tw-h-full tw-border tw-border-white tw-opacity-30"
					></div>
					<img
						v-if="investor.image"
						class="investor-img tw-w-7 tw-h-7 tw-border tw-border-white"
						:src="investor.image"
						alt="investor"
						loading="lazy"
					/>
					<img
						v-else
						class="investor-img tw-w-7 tw-h-7 tw-border tw-border-white"
						src="https://res.cloudinary.com/djalafcj9/image/upload/v1634619880/getequityV2/profile_etgvxn.svg"
						alt="investor"
						loading="lazy"
					/>
				</li>
				<p
					v-if="otherInvestors !== 0"
					class="other-ionvestors tw-absolute tw-text-white tw-text-xs"
				>
					+{{ otherInvestors }}
				</p>
			</ul>
		</div>
	</router-link>
</template>

<script>
import { formatAmountToDollar } from "@/utils/helpers.js";

export default {
	name: "OverviewDealsCard",

	props: {
		topDeals: { type: Object, default: () => {}, required: true },
	},

	data() {
		return {
			otherInvestors: 0,
			totalRaised: 0,
		};
	},

	//  :currentNumber="tokenInfo.total_raised * 10"
	// :totalValue="tokenInfo.raise_amount"

	mounted() {
		this.calcDetails();
	},

	methods: {
		formatAmountToDollar,

		calcDetails() {
			this.totalRaised = this.topDeals.total_raised * this.topDeals.price.exchange;

			this.otherInvestors =
				this.topDeals.totalInvestors - this.topDeals.investors.length;
			if (this.$refs.fill) {
				this.$refs.fill.style.left = `${
					(this.totalRaised / this.topDeals.raise_amount) * 100
				}%`;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.overview-deal {
	&__link {
		border: 1px solid #f1f3f6;
		.gauge-container {
			.gauge {
				.fill {
					top: 0;
					transition: all 0.5s ease-out;
				}
			}
		}

		.investors {
			.overlay {
				border-radius: 50%;
			}

			.investor-img {
				border-radius: 50%;
			}

			.other-ionvestors {
				top: 0.4rem;
				right: 0.5rem;
			}
		}
	}
}
</style>
