<template>
	<div id="transaction-table">
		<!-- <TableHead
      :class="[bgColor]"
      :options="options"
      :tab="option"
      label="options"
      @emitOption="emitOption"
    /> -->
		<div class="tw-w-full tw-overflow-auto" :class="[bgColor]">
			<table class="table tw-w-full">
				<thead v-if="tableRows.length !== 0" class="header">
					<tr class="header-row">
						<th
							class="header-data tw-font-normal tw-py-6"
							ref="tableHead"
							:style="gridStyling"
						>
							<div
								class="tw-justify-self-start tw-text-sm tw-text-[#323E67]"
								v-for="(header, index) in tableHeaders"
								:key="'header' + index"
							>
								{{ header }}
							</div>
						</th>
					</tr>
				</thead>
				<tbody class="table-body">
					<tr class="body-row">
						<td class="body-data tw-min-h-full" ref="tableRow">
							<div
								class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest tw-cursor-pointer"
								v-for="(details, index) in tableRows"
								:key="'row' + index"
								:style="gridStyling"
								@click="viewTransaction(details)"
							>
								<span class="tw-justify-self-end">
									<img
										v-if="
											details?.type.includes('Mint') ||
											details?.type.includes('Fund') ||
											details?.type.includes('Carry') ||
											details?.type.includes('Fund') ||
											details?.type.includes('Buy') ||
											details?.type.includes('Trade Reversal') ||
											details?.type.includes('Subscription')
										"
										src="https://res.cloudinary.com/djalafcj9/image/upload/v1634440971/getequityV2/in-arrow_fwjcx8.webp"
										class="tw-ml-2 tw-w-8"
										alt="in arrow"
										loading="lazy"
									/>
									<img
										v-if="
											details?.type.includes('Withdraw') ||
											details?.type.includes('Debit Wallet') ||
											details?.type.includes('Send') ||
											details?.type.includes('Fee') ||
											details?.type.includes('Sell') ||
											details?.type.includes('Plan')
										"
										src="https://res.cloudinary.com/djalafcj9/image/upload/v1634440971/getequityV2/out-arrow_nsqg5i.webp"
										class="tw-ml-2 tw-w-8"
										alt="out arrow"
										loading="lazy"
									/>
								</span>
								<div
									class="tw-self-center"
									v-for="(detail, index) in details"
									:key="'colunm' + index"
								>
									<p
										v-html="detail"
										class="tw-text-sm"
										:class="{
											[detail.toLowerCase()]:
												availableStatuses.includes(detail),
										}"
									></p>
								</div>
							</div>
							<EmptyState v-if="tableRows.length === 0 && !smallLoading">
								<template v-slot:message>
									<p class="message tw-text-center tw-text-gray tw-my-3">
										There has been no transaction on your account.
									</p>
								</template>
							</EmptyState>
							<SmallLoader
								v-if="smallLoading && table === 'transactionTable'"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="tw-flex tw-justify-end tw-pb-3">
			<Pagination
				v-if="showPagination"
				:totalPage="pages"
				:currentPage="page"
				:maxVisible="5"
				@pageChange="gotoPage"
			/>
		</div>
		<TransactionModal
			@close-modal="showViewTransactionModal = false"
			v-if="showViewTransactionModal"
			:transaction="transaction"
		/>
	</div>
</template>

<script>
import Pagination from "@/components/navigation/Pagination";
import EmptyState from "@/components/notification/EmptyState";
import TableHead from "@/components/tables/TableHeader";
import TransactionModal from "@/components/wallet/TransactionModal";
import {
	formatAmountToDollar,
	formatISODate,
	formatISOTime,
	removeUnderscore,
	truncate,
} from "@/utils/helpers.js";
import { mapActions, mapState } from "vuex";

export default {
	name: "TransactionTable",

	components: {
		Pagination,
		TableHead,
		TransactionModal,
		EmptyState,
	},

	data() {
		return {
			showViewTransactionModal: false,
			transactionRef: "",
			transaction: {
				ref: null,
				description: null,
				image: null,
				amount: null,
				type: null,
				date: null,
				status: null,
			},
			option: "",
			availableStatuses: ["Pending", "Completed", "Failed"],
			options: ["Transactions", "Shares"],
			tableHeaders: [
				"",
				"Transaction Details",
				"Transaction Reference",
				"Amount",
				"Status",
			],
			tableRows: [],
			table: "",
			currentOption: "Transactions",
		};
	},

	props: {
		bgColor: {
			type: String,
			required: false,
			default: () => {
				return "";
			},
		},
		showPagination: {
			type: Boolean,
			default: () => true,
		},
	},

	computed: {
		...mapState({
			smallLoading: (state) => state.smallLoading,
			transactions: (state) => state.organisationModule.orgTransactions,
			page: (state) => state.organisationModule.page,
			pages: (state) => state.organisationModule.pages,
		}),
		gridStyling() {
			return {
				"grid-template-columns": `${50}px repeat(${4}, ${220}px)`,
			};
		},
	},
	mounted() {
		this.emitOption("Transactions");
	},

	methods: {
		...mapActions("organisationModule", ["getTransactions"]),

		viewTransaction(details) {
			this.transaction = {
				ref: details.reference.split(">")[1].split("<")[0],
				description: details.details.split(">")[1].split("<")[0],
				image: details.image.split(">")[1].split("<")[0],
				amount: details.amount.split(">")[1].split("<")[0],
				type: details.type.split(">")[1].split("<")[0],
				date: details.details.split(">")[4].split("<")[0],
				status: details.status,
			};
			this.showViewTransactionModal = true;
		},

		emitOption(option) {
			this.table = "transactionTable";
			this.tableRows = [];
			let type;
			let limit;
			let query;

			switch (option) {
				case "Transactions":
					this.currentOption = null;
					this.option = "Transactions";
					query = { page: 1, limit: 10 };
					this.getTransactions(query);
					break;
				case "Completed":
					this.currentOption = option;
					this.option = "Completed";
					query = { page: 1, status: option, limit: 10 };
					this.getTransactions(query);
					break;
				case "Pending":
					this.currentOption = option;
					this.option = "Pending";
					query = { page: 1, status: option, limit: 10 };
					this.getTransactions(query);
					break;

				default:
					break;
			}
		},

		getCurrency(currency, tokenName) {
			switch (tokenName) {
				case "GetEquity USD Token":
					return "USD";
				case "GetEquity KES Token":
					return "KES";
				case "GetEquity NGN Token":
					return "NGN";
				default:
					return currency;
			}
		},

		updateTableRow(transactions) {
			// this.table = "";
			this.tableRows = transactions.map((transaction) => {
				let type = removeUnderscore(transaction.type);
				switch (type) {
					case "Debit Sell Wallet":
						type = "Debit Wallet";
						break;

					case "Fund Sell Wallet":
						type = "Fund Wallet";
						break;
					default:
						break;
				}
				let name =
					type === "Exit"
						? transaction.token.currency
						: this.getCurrency(
								transaction.token.currency,
								transaction.token.name
						  );
				let volume = transaction.volume.toLocaleString();
				let details = truncate(`${type}: ${volume} ${name}`, 30);
				let amount;
				if (transaction.price) {
					amount = formatAmountToDollar(
						transaction.price * transaction.volume,
						2,
						name
					);
				} else {
					amount = formatAmountToDollar(
						transaction.token?.price.buy * transaction.volume,
						2,
						type === "Exit" ? token.currency : name
					);
				}
				return {
					details: `<span class="tw-font-bold">${details}</span> <br />
          <span class="tw-text-black-light tw-text-xs">${formatISODate(
						transaction.createdAt
					)}  ${formatISOTime(transaction.createdAt)}</span>`,
					reference:
						type === "Fee" ||
						type === "Debit Wallet" ||
						type === "Send" ||
						type === "Withdraw" ||
						type === "Sell" ||
						type === "Plan"
							? `<span class="tw-text-sm tw-mb-1 tw-text-[#323E67]">${truncate(
									transaction.reference,
									25
							  )}</span> <br />
          <span class="tw-text-xs tw-text-error">Debit</span>`
							: `<span class="tw-text-sm tw-mb-1 tw-text-[#323E67]">${truncate(
									transaction.reference,
									25
							  )}</span> <br />
          <span class="tw-text-xs tw-text-success">Credit</span>`,
					amount: amount,
					status: transaction.status,
					type: `<span class="tw-hidden">${type}</span>`,
					image: `<span class="tw-hidden">${transaction.token?.image}</span>`,
				};
			});
		},

		gotoPage(tablePage) {
			this.tableRows = [];
			const query = {
				page: tablePage,
				status: this.currentOption,
				limit: 10,
			};
			this.getTransactions(query);
		},
	},

	watch: {
		transactions(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.updateTableRow(this.transactions);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#transaction-table {
	@include fadeIn;

	.table {
		.header {
			.header-row {
				.header-data {
					display: grid;
					column-gap: 2rem;
				}
			}
		}
		.table-body {
			.body-row {
				.body-data {
					.data {
						display: grid;
						column-gap: 2rem;
						font-size: 0.7rem;

						// Added with javascript
						.completed,
						.pending,
						.failed {
							text-align: left;
							padding: 0.5rem;
							border-radius: 5px;
						}
						.completed {
							color: #228753;
							background-color: #ebfff4;
							max-width: 5.4rem;
						}
						.pending {
							color: #c06c00;
							background-color: #ffeed9;
							max-width: 4.2rem;
						}
						.failed {
							color: $error;
							background-color: $error-light;
							max-width: 3.6rem;
						}
					}
				}
			}
		}
	}
}
</style>
